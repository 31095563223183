@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Averta";
  src:
    local("Averta"),
    url(./fonts/averta-black.otf) format("opentype");
}

@font-face {
  font-family: "Montserrat";
  src:
    local("Montserrat"),
    url(./fonts/montserrat-regular.ttf) format("truetype");
}

body {
  @apply bg-dark-gray;

  margin: 0;
  font-family: "Montserrat", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea {
  @apply border border-black w-full p-4 text-black;
}

button.submit {
  @apply p-4 w-full bg-yellow text-dark-gray;
  font-family: "Averta";
  font-size: 3rem;
}

button.submit:disabled {
  @apply bg-gray-50 cursor-not-allowed;
}

.mainLogo {
  @apply w-full;

  background-image: url("../public/img/logo-white.png");
  width: 35rem;
  height: 35rem;
  background-repeat: no-repeat;
  background-size: contain;
}

.Toastify__toast-body {
  font-family: "Montserrat";
}
